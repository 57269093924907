import React, { useState } from 'react'

import Appcontext from './Appcontexts'

const Provider = ({children}) => {
  
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] =useState('')
  const [number, setNumber] =useState('')
    const values = {
      name,
      setName,
      email,
      setEmail,
      message,
      setMessage,
      number,
      setNumber
    }
  return (
    <Appcontext.Provider value={values}>
        {children}
    </Appcontext.Provider>
  )
}

export default Provider