import React from 'react'
import styled from 'styled-components'
import github from '../midias/img-github.svg'

const FooterStyled = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    padding:20px 0 20px 0;
`

const Footer = () => {
  return (
    <FooterStyled>
        <a href="https://github.com/OtavioBarbosa01" target='_blanck'><img src={github} alt="" /></a>
    </FooterStyled>
  )
}

export default Footer