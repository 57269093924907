import React from 'react'
import styled from 'styled-components'
import StackIcon from 'tech-stack-icons'

const CarrosselStyled = styled.div`
    padding-top:30px;
    .icons{
    width: 40px;
    transition: 0.3s;
    
}

.icons:hover{
   transform: scale(1.1);
}

`

const Carrossel = () => {
  return (
    <CarrosselStyled>
        <StackIcon className='icons' name="html5" />
        <StackIcon className='icons' name="css3" />
        <StackIcon className='icons' name="js" />
        <StackIcon className='icons' name="nodejs" />
        <StackIcon className='icons' name="reactjs" />
        <StackIcon className='icons' name="nextjs" />
        <StackIcon className='icons' name="mongodb" />
        <StackIcon className='icons' name="mysql" />
    </CarrosselStyled>
  )
}

export default Carrossel