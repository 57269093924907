import { ThemeProvider } from "styled-components";
import Globalstyled from "./styles/Globalstyled";
import Header from "./components/Header";
import Main from "./components/Main";
import {Light, Dark} from './styles/Theme'
import { useState } from "react";
import Portfolio from "./components/Portfolio";
import {FloatingWhatsApp} from 'react-floating-whatsapp';
import Perfil from './midias/perfil.jpg'
import Footer from "./components/Footer";
import Contato from "./components/Contato";
import Provider from "./contexts/Provider";
function App() {

  const [theme, setTheme] = useState(Dark)
  const handleTheme = () => {
    setTheme(theme === Light ? Dark : Light)
  }
  const customButtonStyle = {
    width: '50px',
    height: '50px',
  };

  return (
    <ThemeProvider theme={theme} id="barbosa" >
      <Provider>
        <Globalstyled/>
          <Header active={handleTheme}/>
          <Main/>
          <Portfolio/>
          <Contato/>
          <Footer/>
          <FloatingWhatsApp
            phoneNumber="47991398143"
            accountName="Deve Barbosa"
            avatar={Perfil}
            statusMessage="online"
            chatMessage="Olá, como posso ajudar?"
            buttonStyle={customButtonStyle}
          />
      </Provider>
    </ThemeProvider>

  );
}

export default App;
