import Navbar from './Navbar'
import styled from 'styled-components'
import Logo from './Logo'
import { Switch } from '@mui/material'


const HeaderStyled = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px 20px 5px 20px;
    gap:20px;
    background-color: ${prop => prop.theme.colors.primary};

    & > div{
      display:flex;
      align-items:center;
      justify-content:center;
      gap:20px;
      width:100%;

      @media(max-width: 450px){
        gap:10px;
      }
    }


`


const Header = ({active}) => {

    
  return (
    <HeaderStyled>
        <div>
          <Logo/>
          
        </div>
        {/*
          <Switch onChange={active} color='default' />
        */}
    </HeaderStyled>
  )
}

export default Header