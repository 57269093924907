import React from 'react'
import styled from 'styled-components'
import About from './About'
import perfil from '../midias/perfil.jpg'
import Carrossel from './Carrossel'

const MainStyled = styled.div`
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    padding:30px;
    padding-top:100px;

    & > div > img{
        border: 0;
        border-radius: 50%;
        position: relative;
        
    }

    @media(max-width: 800px){
        flex-direction:column;
    }
`

const Main = () => {
  return (
    <MainStyled id='home'>
        <div>
            <img src={perfil} alt="" />
            <Carrossel/>
        </div>
        <About/>
    </MainStyled>
  )
}

export default Main