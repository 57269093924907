import React, { useContext } from 'react'
import Appcontext from '../contexts/Appcontexts'
import styled from 'styled-components'
import emailjs from '@emailjs/browser'

const ContatoStyled = styled.div`
    display:flex;
    justify-content:center;
    padding-top:20px;
    flex-direction:column;
    align-items:center;

    > form{
        display:flex;
        flex-direction:column;
        align-items:center;
        background: ${prop => prop.theme.colors.segundary};
        box-shadow:2px 1px 0 0  purple;
        gap:40px;
        width: 100%;
        max-width:350px;
        min-width:300px;
        padding:50px 20px 10px 20px;
        border-radius: 15px;

        > .input{
            
            padding:5px;
            width:90%;
            border-radius:15px;
            border: none;
            outline: none;
        }
        
        >textarea{
            width: 300px;
            border-radius: 5px;
            max-width: 300px;
            min-width: 300px;
            height:100px;
            min-height:100px;
            max-height:100px;
            border: none;
            outline: none;
        }
    }
`

const ContatoButton = styled.input`
    border:none;
    display:flex;
    justify-content:center;
    color: ${prop => prop.theme.colors.text};
    border-radius: 5px;
    width:20%;
    padding:5px;
    background-color: purple;
    cursor: pointer;
`

const Contato = () => {
    const {name, setName, email, setEmail, message, setMessage, number, setNumber} = useContext(Appcontext)

    const sendEmail = (e) =>{
        e.preventDefault();

        if(name === "" || email === "" || number === "" || message === ""){
            alert('Por favor pleencha todos os campos ');
        return;
        }
        const templateParms = {
            from_name: name,
            email: email,
            tel: number,
            message: message,
        }
        emailjs.send('service_9d4z6qg', 'template_uds1rhk', templateParms, 'e8itLW8nwRzdRdMob').then(res => {
            console.log(res.status, 'gg');
            setName('');
            setEmail('');
            setNumber('');
            setMessage('');
        }
        )
    }

  return (
    <ContatoStyled id='contato'>
        <h1>Contato</h1>
        <form onSubmit={sendEmail}>
            <input className='input' type="text"
            placeholder='Digite o seu nome:'
            onChange={e => setName(e.target.value) }
            value={name}
            required/>

            <input className='input' type="email"
            placeholder='Digite o seu email:'
            onChange={e => setEmail(e.target.value)}
            value={email}
            required />

            <input className='input' type="tel"
            placeholder='(99) 9 9999-9999'
            onChange={e => setNumber(e.target.value)}
            value={number}
            required />

        <textarea 
          className="textarea"
          placeholder="Digite sua mensagem..."
          maxLength="210"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          required/>

            <ContatoButton type='submit' value='Submit'/>
        </form>
    </ContatoStyled>
  )
}

export default Contato