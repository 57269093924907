import React from 'react'
import styled from 'styled-components'

const Astyled = styled.a`
    color: ${prop => prop.theme.colors.background2};
    background:none;
    border:2px solid ${prop => prop.theme.colors.background2};
    border-radius:10px;
    padding:4px;
    font-weight:700;
`

const Links = ({text, links}) => {
  return (
    <Astyled href={links}>{text}</Astyled>
  )
}

export default Links