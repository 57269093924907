import React from 'react'
import styled from 'styled-components'
import Cart from './Cart'
import sneakers from '../midias/sneakers.png'

const PortfolioStyled = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding-bottom:100px;
    padding-top:20px;

    > div {
      gap:20px;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }

`

const Portfolio = () => {
  return (
    <PortfolioStyled id='portfolio'>
        <h1>Portfolio</h1>
        <div>
          <Cart title={'Sneakers'} text={'Projeto desenvolvido para treinar habilidades em React.js e Styled Components'} image={sneakers}
          url={'https://projectsneakers.shop/'}
          />
        </div>
    </PortfolioStyled>
  )
}

export default Portfolio