import React from 'react'
import styled from 'styled-components'

const AboutStyled = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;

    & > p{
      text-align:center;
    }
`

const About = () => {
  return (
    <AboutStyled>
        <h1>About</h1>
        <p>Olá, meu nome é Otavio e sou um desenvolvedor full stack com experiência de um pouco mais de 1 ano na área. Estou comprometido em oferecer soluções de alta qualidade e eficiência para atender às necessidades dos meus clientes. Com habilidades sólidas em desenvolvimento front-end e back-end, estou sempre em busca de novos desafios para aprimorar minhas habilidades e oferecer resultados excepcionais</p>
    </AboutStyled>
  )
}

export default About