import React from 'react'
import styled from 'styled-components'
import Links from './Links'

const NavbarStyled = styled.div`
    display:flex;
    gap:20px;
`

const Navbar = () => {
  return (
    <NavbarStyled>
        <Links links={'home'} text={'Home'}/>
        <Links links={'portfolio'} text={'Portfolio'}/>
        <Links links={'contato'} text={'Contato'}/>
    </NavbarStyled>
  )
}

export default Navbar