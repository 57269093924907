export const Light = {
    colors:{
        primary: 'hsl(26, 100%, 55%)',
        segundary: 'rgb(231, 231, 231)',
        white: 'white',
        text: 'black',
        background:'linear-gradient(90deg, rgba(255,232,215,1) 0%, rgba(255,234,218,1) 49%, rgba(255,255,255,1) 100%)',
        background2:'white',
    }
}
export const Dark = {
    colors:{
        primary: '#dddd',
        segundary: 'rgb(23, 24, 28, 0.5)',
        white: 'white',
        text: '#dddd',
        background:'#141414',
        background2:'#141414',
    }
}