import React from 'react'
import styled from 'styled-components'
import logo from '../midias/favicon-32x32.png'

const LogStyled = styled.img`
width:38px;
height:38px;
`

const Logo = () => {
  return (
   <LogStyled src={logo}/>
  )
}

export default Logo