import React from 'react'
import styled from 'styled-components'

const CartStyled = styled.div`
    width:100%;
    min-width:300px;
    max-width:600px;
    background-color: ${prop => prop.theme.colors.segundary};
    box-shadow:1px 1px 0 purple;
    display:flex;
    border-radius:25px;
    justify-content:center;
    align-items:center;
    padding:10px;
    transition: 1s;
    gap:5px;

    & > div{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }

    & > div > a > img{
        width:250px;
        border-radius:15px;
    }

    & > p{
        width:70%;
    }

    @media(max-width: 800px){
        width:350px;
        flex-direction:column;    
    }

    &:hover{
        transform: scale(1.03);
    }
`

const Cart = ({title, text, image, url}) => {
  return (
    <CartStyled>
        <div>
            <a href={url} target='_blanck'><img src={image} alt="" /></a>
            <h4>{title}</h4>
        </div>
        <p>{text}</p>
    </CartStyled>
  )
}

export default Cart