import { createGlobalStyle } from "styled-components";

const Globalstyled = createGlobalStyle`
    * {
        text-decoration: none;
    }
    body {
        background: ${prop => prop.theme.colors.background};
        color: ${prop => prop.theme.colors.text};
    }
`

export default Globalstyled